<template>
  <div>
    <div>
      <section class="mt-4 mb-3">
        <section class="d-flex justify-content-between align-items-center">
          <div class="input-search col-md-5 p-0">
            <b-form-input
              autocomplete="off"
              @input="handleSearch"
              v-model="search"
              placeholder="Digite para pesquisar"
              class="mb-2"
            />
            <b-button
              variant="light"
              class="px-2 d-flex align-items-center absolute icon-action"
            >
              <b-icon icon="search"></b-icon>
            </b-button>
          </div>
          <b-button class="btn-add" @click="handleOpenModalQuestion()">
            Adicionar
          </b-button>
        </section>
      </section>
      <cards-list grid="5" gap="8">
        <router-link
          v-for="(question, index) in items"
          :key="index"
          :to="{
            name: 'pages/System/Questions',
            params: { id: question.id, current_question: question },
          }"
        >
          <b-card class="p-3 card-insurance card-hoverable pointer" no-body>
            <div>
              <section>
                <div
                  class="d-flex justify-content-between align-items-start"
                  style="min-height: 50px"
                >
                  <strong class="d-block text-primary">
                    {{ question.name }}
                  </strong>
                </div>
                <hr />
                <small class="d-block" style="min-height: 70px">
                  {{ question.description }}
                </small>
              </section>
            </div>
          </b-card>
        </router-link>
      </cards-list>
    </div>
    <empty-list v-if="items && items.length === 0 && !isBusy" text="Nenhum resultado encontrado"/>

    <b-modal
      id="add-question-type"
      centered
      hide-footer
      size="md"
      title="Adicionar"
      no-close-on-backdrop
    >
      <Form :id="selectedId" isAdd @submited="handleCloseModal" />
    </b-modal>
  </div>
</template>
<script>
import { listPaginated } from "@/mixins/listPaginated";
import { mapActions } from "vuex";
import Form from "./form.vue";

export default {
  components: {
    Form,
  },
  mixins: [listPaginated],
  data() {
    return {
      empty: false,
      selectedTpl: {},
      selectedId: null,
      action_name: "questions_type",
      questions: [],
    };
  },

  methods: {
    ...mapActions(["get_all_questions_type"]),

    handleOpenModalQuestion() {
      this.selectedId = null;
      this.$bvModal.show("add-question-type");
    },
    handleCloseModal() {
      this.$bvModal.hide("add-question-type");
      this.getList();
    },
  },
};
</script>
