import _ from "lodash";
const listPaginated = {
  props: {
    button_name: {
      type: String,
    },
    action: {
      type: String,
    },
    base_view: {
      type: String,
    },
    title: {
      type: String,
    },
    fields: {
      type: Array,
    },
  },
  data() {
    return {
      selected: {},
      action_name: null,
      status: null,
      paginate: false,
      search: null,
      per_page: 20,
      current_page: 1,
      total: 1,
      isBusy: false,
      isEmpty: false,
      items: [],
      summary: [],
      clear_list: true,
    };
  },
  methods: {
    handleChangePage(current_page) {
      this.current_page = current_page;
      this.getList();
    },
    handleChangePerPage(per_page) {
      this.per_page = per_page;
      this.getList();
    },
    handleSearch: _.debounce(function(value) {
      this.search = value;
      this.getList();
    }, 500),
    getList() {
      return new Promise((resolve) => {
        this.isBusy = true;

        if (this.clear_list) this.items = [];

        const params = {
          ...this.params,
          current_page: this.current_page,
          per_page: this.per_page,
          search: this.search,
        };
        if (this.paginate) {
          this.$store
            .dispatch(`get_all_${this.action_name || this.action}`, params)
            .then(({ data, status }) => {
              const { results, current_page, per_page, total, summary } = data;
              this.isBusy = false;
              this.status = status;
              this.items = results;
              this.summary = summary;
              this.isEmpty = results?.length === 0;
              this.current_page = current_page === 0 ? 1 : current_page;
              this.per_page = per_page;
              this.total = total;
              resolve(data);
            });
        }
        this.$store
          .dispatch(`get_all_${this.action_name || this.action}`, params)
          .then(
            ({ data: { results, current_page, per_page, total, summary } }) => {
              this.isBusy = false;
              this.items = results;
              this.summary = summary;
              this.isEmpty = results?.length === 0;
              this.current_page = current_page === 0 ? 1 : current_page;
              this.per_page = per_page;
              this.total = total;
              resolve(results);
            }
          );
      });
    },
    handleConfirmDelete(item) {
      this.selected = item;
      this.$bvModal.show(`delete-row_${item.id}`);
    },
    handleDeleteRow() {
      this.$store
        .dispatch(`delete_${this.action_name || this.action}`, this.selected.id)
        .then(() => {
          this.getList();
          this.selected = {};
          this.$bvModal.hide(`delete-row_${this.selected.id}`);
          this.$root.$emit("deleted-event");
        });
    },
  },
  created() {
    this.$root.$on("bv::modal::hidden", (bevent, id) => {
      if (id.split("_").includes("delete-row")) {
        this.selected = {};
      }
    });
  },
  mounted() {
    this.getList();
    this.$root.$on("update_list", () => this.getList());
  },
  watch: {
    params: {
      handler(newValue, oldValue) {
        if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
          this.getList();
        }
      },
      deep: true,
    },
    role: {
      handler() {
        this.getList();
      },
      deep: true,
    },
  },
};
export { listPaginated };
